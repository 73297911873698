import { activeTabId } from "../profile/sidebar";
import { createOpenPositionBlock, displayWell } from "./components";
import { getJobsData } from "./getJobsData";
import { hideLoadingIndicator, showLoadingIndicator } from "./loading";

export function getOpenPositions() {
	const openPositionsBlock = document.getElementById("openPositions");

	showLoadingIndicator();

	let params = {
		status: "Published",
	};

	const locationValue = getLocationValue();
	if (locationValue) {
		params = { ...params, locations: [locationValue] };
	}

	const searchInputValue = getFreeSearchValue();
	if (searchInputValue) {
		params = { ...params, contains: searchInputValue };
	}

	const department = getDepartmentId();
	if (department) {
		params = { ...params, departments: [parseInt(department)] };
	}

	const successHandler = (results) => {
		openPositionsBlock.replaceChildren();
		hideLoadingIndicator();

		if (results.length > 0) {
			fillDepartmentsPosCount(results);

			results.forEach((result) => {
				const position = createOpenPositionBlock(result);
				openPositionsBlock.appendChild(position);
			});
		} else {
			openPositionsBlock.appendChild(
				displayWell("No open positions found."),
			);
		}
	};

	const failureHandler = (error) => {
		hideLoadingIndicator();
		openPositionsBlock.appendChild(
			displayWell(`${error.message} Please try again later.`),
		);
	};

	getJobsData("/positions", params, successHandler, failureHandler);
}

function getDepartmentId() {
	const departmentsList = document.getElementById("openPositionsDepartments");
	const activeDepartmentTab = activeTabId();

	if (!departmentsList && !activeDepartmentTab) {
		return;
	}

	if (departmentsList) {
		return departmentsList
			.querySelector("[aria-selected='true']")
			?.getAttribute("id");
	}

	if (activeDepartmentTab) {
		const tabs = document.getElementById("departmentsTabs");
		return tabs.querySelector("a[aria-selected='true']")?.dataset.id;
	}
}

function getLocationValue() {
	const dropdownList = document.getElementById("locationChoice");

	if (!dropdownList) {
		return;
	}

	return dropdownList.querySelector("[data-active='true']")?.dataset.value;
}

function getFreeSearchValue() {
	const searchInput = document.getElementById("openPositionsSearch");

	if (!searchInput) {
		return;
	}

	return searchInput.value;
}

function fillDepartmentsPosCount(data) {
	const departmentsList = document.getElementById("openPositionsDepartments");
	const hasCountFilled = departmentsList?.dataset.counts === "true";

	if (!departmentsList || hasCountFilled) {
		return;
	}

	const departments = departmentsList.querySelectorAll(".list-item");

	departments.forEach((department) => {
		const id = department.getAttribute("id");
		addCount(
			department,
			id
				? data.filter((result) => result.department.id === parseInt(id))
						.length
				: data.length,
		);
	});

	function addCount(button, count) {
		const buttonName = button.innerText;
		button.innerText = `${buttonName} (${count})`;
	}

	departmentsList.dataset.counts = "true";
}
