import { waitForElement } from "../common/waitForElement";

export function stickyTableHeaders() {
	waitForElement(".content-box").then(() => {
		const getStickyHeaders = () => {
			return document.querySelectorAll(
				".table-container > table > thead.sticky",
			);
		};

		const stickyHeaders = getStickyHeaders();

		if (stickyHeaders.length > 0) {
			setStickyTableHeaders();
			window.addEventListener("resize", () => setStickyTableHeaders());

			function setStickyTableHeaders() {
				stickyHeaders.forEach((stickyHeader) => {
					const container = stickyHeader.parentElement.parentElement;

					// Has horizontal scroll and overflows visible area of the screen
					const hasHorizontalScroll =
						container.offsetWidth <
						container.firstElementChild.offsetWidth;
					const overflowsScreenHeight =
						container.offsetHeight > window.innerHeight;
					if (hasHorizontalScroll) {
						if (overflowsScreenHeight) {
							container.style.setProperty(
								"height",
								"calc(100vh - 10rem)",
							);
							container.style.paddingTop = "0";
						}

						container.style.overflowX = "auto";
						document.body.style.overflow = "";
						stickyHeader.style.top = "";
					} else {
						container.style.overflow = "visible";
						container.style.height = "";
						container.style.paddingTop = "";
						document.body.style.overflow = "visible";
					}
				});

				// Listen to sticky header on scroll up
				const header = document.querySelector("header");
				const observer = new MutationObserver((mutations) => {
					mutations.forEach(() => {
						const stickyHeaders = getStickyHeaders();
						if (stickyHeaders.length > 0) {
							stickyHeaders.forEach((stickyHeader) => {
								const container =
									stickyHeader.parentElement.parentElement;

								if (header.classList.contains("scroll-up")) {
									const hasHorizontalScroll =
										container.offsetWidth <
										container.firstElementChild.offsetWidth;
									if (!hasHorizontalScroll) {
										stickyHeader.style.top =
											header.offsetHeight + "px";
									}
								} else if (
									header.classList.contains("scroll-down")
								) {
									stickyHeader.style.top = "";
								}
							});
						} else {
							observer.disconnect();
						}
					});
				});

				observer.observe(header, {
					attributes: true,
					attributeFilter: ["class"],
				});
			}
		}
	});
}
