export function hideLoadingIndicator() {
	const openPositions = document.getElementById("openPositions");
	openPositions.classList.remove("loading");
	openPositions.querySelector(".loader")?.classList.add("hidden");
}

export function showLoadingIndicator() {
	const openPositions = document.getElementById("openPositions");

	if (openPositions.classList.contains("loading")) {
		return;
	}

	openPositions.firstChild && openPositions.replaceChildren();
	openPositions.classList.add("loading");

	const existingLoader = openPositions.querySelector(".loader");

	if (existingLoader) {
		existingLoader.classList.remove("hidden");
	} else {
		const loader = document.createElement("div");
		loader.classList.add("loader");
		openPositions.appendChild(loader);
	}
}