export function scroll(header) {
	const headerGrey = "header-grey";
	const headerIsTransparent = header.classList.contains("header-transparent");
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	let lastScroll = 0;

	const setScrollDownClasses = () => {
		header.classList.remove(scrollUp);
		header.classList.add(scrollDown);
	};

	const setScrollUpClasses = () => {
		header.classList.remove(scrollDown);
		header.classList.add(scrollUp);
	};

	const initScroll = () => {
		const initialScroll = window.scrollY;
		const isMobile = window.innerWidth < 576;
		const scrollOffset = isMobile ? 20 : 0;

		const setGreyBackground = () => {
			headerIsTransparent && header.classList.add(headerGrey);
		};

		const setScrollClasses = (currentScroll) => {
			if (currentScroll <= scrollOffset) {
				if (!isMobile) {
					header.classList.remove(scrollUp);
					header.classList.remove(scrollDown);
				}

				headerIsTransparent && header.classList.remove(headerGrey);
				return;
			}

			setGreyBackground();

			if (isMobile) {
				return;
			}

			if (
				currentScroll > lastScroll &&
				!header.classList.contains(scrollDown)
			) {
				// Down
				setScrollDownClasses();
			} else if (
				currentScroll < lastScroll &&
				header.classList.contains(scrollDown)
			) {
				// Up
				setScrollUpClasses();
			}

			lastScroll = currentScroll;
		};

		setScrollClasses(initialScroll);

		window.addEventListener("scroll", () => {
			const currentScroll = window.scrollY;
			setScrollClasses(currentScroll);
		});
	};

	initScroll();

	const supportsOrientationChange = "onorientationchange" in window,
		orientationEvent = supportsOrientationChange
			? "orientationchange"
			: "resize";

	window.addEventListener(
		orientationEvent,
		function () {
			initScroll();
		},
		false,
	);
}
