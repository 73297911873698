import { getPositionDetails } from "./components";
import { getJobsData } from "./getJobsData";

export function getJobDescription(id) {
	const successHandler = (data) => {
		const positionTitle = document.querySelector("h1");
		positionTitle.innerText = data.title;

		const positionDetails = document.getElementById("positionDetails");
		positionDetails.classList.add("hero__details");
		getPositionDetails(positionDetails, data);

		const container = document.getElementById("jobDescription");
		container.innerHTML = data.description;

		const applyLinks = document.querySelectorAll("a[data-apply]");
		applyLinks.forEach((link) => {
			link.href = `${data.absoluteUrl}/new`;
		});
	};

	if (id) {
		getJobsData(`/position/${id}`, {}, successHandler, () => {});
	}
}
