export function accordion() {
	const accordions = document.querySelectorAll(".accordion");

	accordions.forEach(function (accordion) {
		const accordionButtons = accordion.querySelectorAll(".accordion-toggle");

		accordionButtons.forEach(function (button) {
			button.addEventListener("click", function (e) {
				e.preventDefault();
				const expanded = button.getAttribute("aria-expanded");

				if (expanded === "true") {
					collapse(button);
				} else {
					accordionButtons.forEach(function (item) {
						collapse(item);
					})
					expand(button);
				}
			});
		});
	});

	function expand(item) {
		item.setAttribute("aria-expanded", "true");
	}

	function collapse(item) {
		item.setAttribute("aria-expanded", "false");
	}
}
