import { getDropdownItem } from "./components";
import { getJobsData } from "./getJobsData";
import { getOpenPositions } from "./getOpenPositions";

export function getJobsLocations() {
	window.initDropdowns?.();
	const dropdownList = document.getElementById("locationChoice");
	const allLocationsButton = dropdownList.querySelector(
		"button.dropdown-item",
	);

	allLocationsButton.addEventListener("click", () =>
		onDropdownItemClick(allLocationsButton),
	);

	const successHandler = (data) => {
		data.forEach((location) => {
			const dropdownItem = getDropdownItem(location);
			dropdownList.appendChild(dropdownItem);
		});
	};

	getJobsData("/job-locations", {}, successHandler, () => {});
}

export function onDropdownItemClick(button) {
	const dropdown = button.parentElement.parentElement;

	dropdown.querySelectorAll(".dropdown-item").forEach((item) => {
		item.dataset.active = "false";
	});

	button.dataset.active = "true";
	getOpenPositions();
}
