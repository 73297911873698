import { onDepartmentItemClick } from "./getDepartments";
import { onDropdownItemClick } from "./getJobsLocations";

export function createOpenPositionBlock(result) {
	const title = result.title;

	// Parent
	const position = document.createElement("div");
	position.classList.add("position");

	// Position details
	const positionContent = document.createElement("div");
	positionContent.classList.add("content");
	const positionTitle = document.createElement("p");
	positionTitle.classList.add("title");
	positionTitle.innerText = title;

	const positionDetails = document.createElement("div");
	positionDetails.classList.add("details");
	getPositionDetails(positionDetails, result);

	positionContent.append(positionTitle, positionDetails);

	const positionMore = document.createElement("div");
	positionMore.classList.add("read-more");

	const positionMoreLink = document.createElement("a");
	positionMoreLink.classList.add("btn", "btn--icon", "btn--next");
	positionMoreLink.setAttribute(
		"href",
		`${window.location.protocol}//${window.location.host}/careers/position/${result.id}`,
	);
	positionMoreLink.innerText = `Read more about ${title} position`;

	positionMore.appendChild(positionMoreLink);
	position.append(positionContent, positionMore);

	return position;
}

export function getPositionDetails(parent, position) {
	const department = position.department.name;
	const contract = position.contract;
	const location = position.jobLocation;

	const positionDetails1 = document.createElement("p");
	positionDetails1.innerText = contract
		? `${department}, ${contract}`
		: department;
	const positionDetails2 = document.createElement("p");
	positionDetails2.innerText = location;

	parent.append(positionDetails1, positionDetails2);
}

export function displayWell(content) {
	const well = document.createElement("div");
	well.classList.add("well", "well--grey");
	const text = document.createElement("p");
	text.innerText = content;

	well.appendChild(text);
	return well;
}

// Jobs locations dropdown
export function getDropdownItem(location) {
	const title = location.name;
	const value = location.id;

	const item = document.createElement("li");
	const button = document.createElement("button");
	button.setAttribute("type", "button");
	button.classList.add("dropdown-item");
	button.dataset.value = value;
	button.innerText = title;

	button.addEventListener("click", () => onDropdownItemClick(button));
	item.appendChild(button);

	return item;
}

// Jobs locations dropdown
export function getDepartmentItem({ id, name, selected = "false" } = {}) {
	const item = document.createElement("li");
	const button = document.createElement("button");
	button.setAttribute("type", "button");
	button.setAttribute("aria-selected", selected);
	id && button.setAttribute("id", id);
	button.classList.add("btn", "list-item");
	button.innerText = name;

	button.addEventListener("click", () => onDepartmentItemClick(button));
	item.appendChild(button);

	return item;
}
