import { setSelectedTab } from "../common/tabs";
import { activeTabId } from "../profile/sidebar";
import { getJobsData } from "./getJobsData";
import { getOpenPositions } from "./getOpenPositions";

export function initDepartmentsTabs() {
	const tabsContainer = document.getElementById("departmentsTabs");
	const tabs = tabsContainer.querySelectorAll("li > a");

	setActiveTab();

	function debounce(func, timeout = 2000) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	const processChange = debounce((e) => {
		e.preventDefault();
		setActiveTab();
		getOpenPositions();
	});

	window.addEventListener("hashchange", processChange);

	tabs.forEach((tab) => {
		tab.addEventListener("click", (e) => {
			e.preventDefault();
			location.hash = `#${tab.dataset.tab}`;
		});
	});

	const successHandler = (data) => {
		const departmentTabsIds = [
			{
				id: "20130",
				name: "data",
			},
			{
				id: "21453",
				name: "development",
			},
			{
				id: "20657",
				name: "hr",
			},
			{
				id: "20128",
				name: "marketing",
			},
			{
				id: "20129",
				name: "publishing",
			},
			{
				id: "20127",
				name: "sales",
			},
		];

		tabs.forEach((tab) => {
			const departmentName = tab.dataset.tab;
			const otherDepartmentId = "19833";
			const departmentMappingId =
				departmentTabsIds.find((dep) => dep.name === departmentName)
					?.id || otherDepartmentId;

			if (
				data.find((item) => item.id.toString() === departmentMappingId)
			) {
				tab.setAttribute("data-id", departmentMappingId);
			}
		});

		getOpenPositions();
	};

	getJobsData("/departments", {}, successHandler, () => {});

	function setActiveTab() {
		const defaultTabId = "sales";
		const activeTab = tabsContainer.querySelector(
			`a[data-tab="${activeTabId(defaultTabId)}"]`,
		);
		const defaultTab = tabsContainer.querySelector(
			`a[data-tab="${defaultTabId}"]`,
		);

		activeTab ? setSelectedTab(activeTab) : setSelectedTab(defaultTab);
	}
}
