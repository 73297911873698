import { getDepartmentItem } from "./components";
import { getJobsData } from "./getJobsData";
import { getOpenPositions } from "./getOpenPositions";

export function getDepartments() {
	const departmentsList = document.getElementById("openPositionsDepartments");

	if (!departmentsList) {
		return;
	}

	const successHandler = (data) => {
		departmentsList.appendChild(
			getDepartmentItem({
				name: "All departments",
				selected: "true",
			}),
		);

		data.forEach((department) => {
			const listItem = getDepartmentItem({
				id: department.id,
				name: department.name,
			});
			departmentsList.appendChild(listItem);
		});
	};

	getJobsData("/departments", {}, successHandler, () => {});
}

export function onDepartmentItemClick(button) {
	const list = button.parentElement.parentElement;

	list.querySelectorAll(".list-item").forEach((button) => {
		button.setAttribute("aria-selected", "false");
		button.classList.remove("active");
	});

	button.setAttribute("aria-selected", "true");
	button.classList.add("active");
	getOpenPositions();
}
