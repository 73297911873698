import * as Sentry from "@sentry/browser";
import { displayToasts, successToast } from "./common/toasts/displayToasts";

export function copyLink() {
	const buttons = document.querySelectorAll(".btn--copy-link");

	if (buttons.length > 0) {
		buttons.forEach((button) => {
			button.addEventListener("click", async () => {
				await navigator.clipboard.writeText(window.location.href).then(
					() => {
						displayToasts([
							successToast("Link successfully copied."),
						]);
					},
					(error) => {
						Sentry.captureException(error);
					},
				);

				button.blur();
			});
		});
	}
}
