import { renderAllTabsOnPrint } from "../common/print/renderAllTabsOnPrint";
import { initRestrictedPopovers } from "../common/restrictedPopovers";
import { waitForElement } from "../common/waitForElement";
import { initSidebar } from "./sidebar";
import { stickyTableHeaders } from "./stickyTableHeaders";
import { toggleRows } from "./toggleRows";

export function initProfile(defaultTabId) {
	initSidebar(defaultTabId);
	renderAllTabsOnPrint();

	waitForElement(".content-box").then(() => {
		initRestrictedPopovers();
		toggleRows();
	});

	window.initFeedbackPopup(".feedback-link");
	window.initModals();
	stickyTableHeaders();

	window.addEventListener("hashchange", function (e) {
		e.preventDefault();
		window.initFeedbackPopup(".feedback-link");
		window.initModals();
		stickyTableHeaders();
	});
}
