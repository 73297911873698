import { getOpenPositions } from "./getOpenPositions";
import { showLoadingIndicator } from "./loading";

export function initSearchInput() {
	const searchInput = document.getElementById("openPositionsSearch");
	let query = "";
	let request;
	let timeout;

	if (searchInput) {
		searchInput.addEventListener("input", () => {
			query = searchInput.value;

			if (query.length > 1) {
				if (request != null) {
					request.abort();
					request = null;
				}

				showLoadingIndicator();
				clearTimeout(timeout);
				timeout = setTimeout(() => getOpenPositions(), 1000);
			} else {
				// If input was cleared, abort request
				clearRequest();
			}
		});

		function clearRequest() {
			if (request != null) {
				request.abort();
				request = null;
			}
		}
	}
}
