import $ from 'jquery';

export function updatePrices(vatFactor) {
    $(document).ready(function () {
        let updateTotalPrices = function () {
            $('form.subscription-modules-upgrade').each(function () {
                let $table = $(this);
                let $checkboxes = $table.find('.module input[type=checkbox]');
                let modules = {};
                $checkboxes.each(function () {
                    let id = $(this).val();
                    let moduleId = $(this).data('module-id');
                    modules[moduleId] = {
                        id: id,
                        checked: $(this).prop('checked'),
                        disabled: $(this).data('pre-disabled') === 1,
                        dependency: $(this).data('dependency'),
						starterDependency: $(this).data('dependency-on-starter'),
                        price: parseFloat($(this).data('price'))
                    }
                });

                $.each(modules, function (moduleId, module) {
                    if (module.checked && module.dependency > 0) {
                        let dependency = modules[module.dependency];
                        dependency.checked = true;
                        dependency.disabled = true;
                    }

					if (module.checked && module.starterDependency > 0) {
						let starterDependency = modules[module.starterDependency];
						starterDependency.checked = true;
						starterDependency.disabled = true;
					}
                });

                $checkboxes.each(function () {
                    let moduleId = $(this).data('module-id');
                    let module = modules[moduleId];
                    $(this).prop('checked', module.checked);
                    $(this).prop('disabled', module.disabled);
                });

                let total = 0;
                $.each(modules, function (moduleId, module) {
                    total += module.checked ? module.price : 0;
                });

                // let $vatContainer = $table.find('.vat-container');
                // let vatFactor = parseFloat($vatContainer.data('vat-factor'));
                //
                // $table.find('.price-net').text(total.toFixed(2));
                // $vatContainer.find('.vat').text((total * vatFactor).toFixed(2));

                if (vatFactor > 0) {
                    let vatAmount = total * vatFactor;
                    let totalPriceVat = total + (total * vatFactor);

                    $table.find('.price-total-excluded-vat .value').text(displayPrice(total));
                    $table.find('.price-total-vat .value').text(displayPrice(vatAmount));
                    $table.find('.price-total .value').text(displayPrice(totalPriceVat));
                } else {
                    $table.find('.price-total .value').text(displayPrice(total));
                }
            });
        };
        updateTotalPrices();
        $('.module input[type=checkbox]').on('click', updateTotalPrices);
    });
}

export function displayPrice(value) {
    return value.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})
}