import { initToggles } from "../toggle";
import { removeOverlay } from "../utils";
import { waitForElement } from "../waitForElement";

export function profilePageHeader(header, breakpoint) {
	const sidebar = document.getElementById("profileSidebar");
	const printMode = window.matchMedia("print").matches;

	if (!sidebar) {
		return null;
	}

	waitForElement("#profileSidebar li.active a").then(() => {
		toggle.textContent = sidebar
			.querySelector("li.active a")
			.textContent.trim();
	});

	const sectionNav = document.querySelector("div.section__nav");
	const toggle = document.querySelector('[data-toggle="profileSidebar"]');

	const setSidebarHeight = () => {
		const sidebar = document.getElementById("profileSidebar");
		const offset =
			sectionNav.getBoundingClientRect().top + sectionNav.offsetHeight;

		if (window.innerWidth < breakpoint) {
			sidebar.setAttribute(
				"style",
				`height: calc(100svh - ${offset}px); top: ${offset}px`,
			);
			toggle.setAttribute("tabindex", "0");
		} else {
			sidebar.setAttribute("style", "");
			toggle.setAttribute("tabindex", "-1");

			if (sidebar.classList.contains("show")) {
				sidebar.classList.remove("show");
				toggle.setAttribute("aria-expanded", "false");
				onToggleClick();
				removeOverlay();
			}
		}
	};

	const setContentPadding = () => {
		const content = document.querySelector(".section--content");
		const headerHeight = sectionNav.offsetHeight;

		if (window.innerWidth < breakpoint && !printMode) {
			content.setAttribute("style", `padding-top: ${headerHeight}px`);
		} else {
			content.setAttribute("style", "");
		}
	};

	const onToggleClick = () => {
		const header = toggle.parentElement.parentElement;
		header.classList.toggle("opened");
		document.documentElement.classList.toggle("backdrop--opened");
		setSidebarHeight();
	};

	if (!printMode) {
		window.addEventListener("resize", () => {
			setSidebarHeight();
			setContentPadding();
		});
	}

	if (window.innerWidth < breakpoint && !printMode) {
		toggle.setAttribute("tabindex", "0");

		document.addEventListener("scroll", () => {
			setSidebarHeight();
		});
	}

	setContentPadding();
	initToggles(onToggleClick);
}
