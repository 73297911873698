import Swiper, { Navigation, Pagination } from "swiper";

export function initSwiper(container, options = {}) {
	const defaultOptions = {
		slidesPerView: 1,
		speed: 400,
		spaceBetween: 10,
		modules: [Navigation, Pagination],
	};

	return new Swiper(container, Object.assign({}, defaultOptions, options));
}
