import { getDepartments } from "./getDepartments";
import { getJobsLocations } from "./getJobsLocations";
import { getOpenPositions } from "./getOpenPositions";
import { initSearchInput } from "./initSearchInput";

export async function initOpenPositions() {
	getDepartments();
	getJobsLocations();
	initSearchInput();
	getOpenPositions();
}
