export function displayLeadBoosterChat(playbookUuid) {
	window.pipedriveLeadboosterConfig = {
		base: "leadbooster-chat.pipedrive.com",
		companyId: 146836,
		playbookUuid: playbookUuid,
		version: 2,
	};
	(function () {
		let w = window;
		if (w.LeadBooster) {
			// eslint-disable-next-line no-console
			console.warn("LeadBooster already exists");
		} else {
			w.LeadBooster = {
				q: [],
				on: function (n, h) {
					this.q.push({ t: "o", n: n, h: h });
				},
				trigger: function (n) {
					this.q.push({ t: "t", n: n });
				},
			};
		}
	})();
}
