import $ from 'jquery';

export function handleModuleSelection(){
	let defaultCommercialName =
		"Commercial Aviation News, Operator & Airport Data";
	let defaultBusinessName = "Business Aviation Operator & Airport Data";

	if($('form:contains("Business Aviation Operator & Airport Data")').length === 0){
		defaultBusinessName = "Business Aviation News";
	}
	$(".checkbox input").each(function() {
		let input = $(this);
		input.change(function() {
			let moduleName = input.closest("label").text();
			if (this.checked) {
				let defaultCommercialInput = findDefaultInput(
					defaultCommercialName,
				);
				let defaultBusinessInput =
					findDefaultInput(defaultBusinessName);
				if (
					moduleName.includes("Commercial") &&
					null !== defaultCommercialInput
				) {
					defaultCommercialInput.prop("checked", true);
					defaultCommercialInput.attr("disabled", true);
				}
				if (
					moduleName.includes("Business") &&
					null !== defaultBusinessInput
				) {
					defaultBusinessInput.prop("checked", true);
					defaultBusinessInput.prop("disabled", true);
				}
			} else {
				uncheckDefaultModule(moduleName, defaultCommercialName, defaultBusinessName);
			}
		});
	});
}

export function findDefaultInput(defaultName) {
	let input = null;
	$(".checkbox label").each(function() {
		let label = $(this);
		if (label.text().trim() === defaultName) {
			input = label.find("input");
		}
	});

	return input;
}

export function uncheckDefaultModule(moduleName, defaultCommercialName, defaultBusinessName) {
	let moduleNamePrefix = moduleName.includes("Commercial")
		? "Commercial"
		: "Business";
	let defaultName = moduleName.includes("Commercial")
		? defaultCommercialName
		: defaultBusinessName;
	let shouldUncheck = true;
	$(".checkbox label").each(function() {
		let label = $(this);
		if (
			label.text().includes(moduleNamePrefix) &&
			label.text().trim() !== defaultName
		) {
			if (label.find("input").is(":checked")) {
				shouldUncheck = false;
			}
		}
	});

	if (shouldUncheck) {
		let defaultInput = findDefaultInput(defaultName);
		if (null !== defaultInput) {
			defaultInput.prop("checked", false);
			defaultInput.prop("disabled", false);
		}
	}
}