import { onContentLoad } from "./onContentLoad";

export function tabs() {
	onContentLoad(function () {
		const tabsNavSelector = ".tabs-nav .tabs-list li > a";
		const tabs = document.querySelectorAll(tabsNavSelector);
		const firstTab = tabs[0];
		const lastTab = tabs[tabs.length - 1];

		if (tabs) {
			tabs.forEach((tab) => {
				tab.addEventListener("click", (e) => {
					e.preventDefault();
					setSelectedTab(tab);
				});

				tab.addEventListener("keydown", (e) => {
					const target = e.currentTarget;
					let flag = false;

					switch (e.key) {
						case "ArrowLeft":
							setSelectedToPreviousTab(target);
							flag = true;
							break;

						case "ArrowRight":
							setSelectedToNextTab(target);
							flag = true;
							break;

						case "Home":
							setSelectedTab(firstTab, true);
							flag = true;
							break;

						case "End":
							setSelectedTab(lastTab, true);
							flag = true;
							break;

						default:
							break;
					}

					if (flag) {
						e.stopPropagation();
						e.preventDefault();
					}
				});
			});

			function setSelectedToPreviousTab(currentTab) {
				let index;

				if (currentTab === firstTab) {
					setSelectedTab(lastTab, true);
				} else {
					index = Array.prototype.slice
						.call(tabs)
						.indexOf(currentTab);
					setSelectedTab(tabs[index - 1], true);
				}
			}

			function setSelectedToNextTab(currentTab) {
				let index;

				if (currentTab === lastTab) {
					setSelectedTab(firstTab, true);
				} else {
					index = Array.prototype.slice
						.call(tabs)
						.indexOf(currentTab);
					setSelectedTab(tabs[index + 1], true);
				}
			}
		}
	});
}

export function setSelectedTab(tab, setFocus) {
	const activeClass = "is-active";
	const tabsContainer = tab.closest(".tabs");
	const tabsNavSelector = ".tabs-nav .tabs-list li > a";
	const tabsContentSelector = ".tabs-content .tab-pane";

	removeActiveTab(tabsContainer);
	addActiveTab(tab, tabsContainer, setFocus);

	function removeActiveTab(tabsContainer) {
		tabsContainer.querySelectorAll(tabsNavSelector).forEach((tab) => {
			tab.parentElement.classList.remove(activeClass);
			tab.setAttribute("aria-selected", "false");
			tab.setAttribute("tabindex", "-1");
		});
		tabsContainer
			.querySelectorAll(tabsContentSelector)
			.forEach((section) => {
				section.classList.remove(activeClass);
				section.setAttribute("tabindex", "-1");
			});
	}

	function addActiveTab(tab, tabsContainer, setFocus) {
		const href = tab.getAttribute("href");
		const matchingSection = tabsContainer.querySelector(href);

		if (setFocus) {
			tab.focus();
		}

		tab.parentElement.classList.add(activeClass);
		tab.setAttribute("aria-selected", "true");
		tab.setAttribute("tabindex", "0");
		matchingSection.classList.add(activeClass);
		matchingSection.setAttribute("tabindex", "0");
	}
}
