import jquery from "jquery";
import { reportErrorToSentry } from "../sentry";

export function getJobsData(namespace, params, successHandler, failureHandler) {
	jquery
		.ajax({
			type: "GET",
			url: `${window.location.protocol}//${window.location.host}/api/careers${namespace}?${new URLSearchParams(params).toString()}`,
		})
		.done((data) => successHandler(data))
		.fail((error) => {
			failureHandler(error);
			reportErrorToSentry(error);
		});
}
