import { closeBurgerMenu } from "../header/toggleMenu";

export function showCookiesSettings(header) {
	const cookiesSettingsButtons = document.querySelectorAll(
		'[data-show="cookiesSettings"]',
	);

	window.addEventListener("sp_init", function () {
		cookiesSettingsButtons.forEach(function (button) {
			button.disabled = false;
		});
	});

	cookiesSettingsButtons.forEach((button) => {
		button.addEventListener("click", () => {
			// eslint-disable-next-line no-undef
			sp.showPrivacyBanner();

			closeBurgerMenu(
				header.querySelector('[data-submenu="headerBurgerMenu"]')
					.parentElement,
			);
		});
	});
}
